import React, { useState, useEffect, useRef, ReactElement } from "react";
import GoogleMapReact from "google-map-react";
// import useGeolocation from "react-hook-geolocation";
import Marker from "../MarkerPromo";
import moment from "moment";

function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
      style: maps.ZoomControlStyle.SMALL,
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
  };
}

function ProviderMap(props) {
  // const geolocation = useGeolocation();
  let [currentlat, setCurrentLat] = useState(null);
  let [currentlon, setCurrentLon] = useState(null);
  let [search, setSearch] = useState("All");

  var defaultProps = {
    center: {
      lat: parseFloat(props.lat),
      lng: parseFloat(props.lon),
    },
    zoom: 11,
  };

  useEffect(() => {
    setCurrentLat(props.lat);
    setCurrentLon(props.lon);
    setSearch(props.searchTerm);
  }, [props.lat, props.lon, props.searchTerm, props.locations]);

  return (
    // Important! Always set the container height explicitly
    <div className="mapDiv">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E" }}
        center={[props.lat, props.lon]}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}
      >
        {props.locations.map((locations) => {
          var title = null;
          if (locations.promoted) {
            props.providers.map((provider) => {
              if (provider.cpid == locations.cpid) {
                title = provider.title;
              }
            });
            return (
              <Marker
                key={locations.rangeKey}
                lat={parseFloat(locations.geoJson.split(",")[0])}
                lng={parseFloat(locations.geoJson.split(",")[1])}
                name={title}
                location={locations.organization}
                title={locations.description}
                color={"#0583F2"}
              />
            );
          }
        })}
      </GoogleMapReact>
    </div>
  );
}

export default ProviderMap;
