import React, { useState, useEffect, useRef, ReactElement } from "react";
import "./Marker.css";
import icon from "./Vector.png";

// const Marker = props => {
//   return <div className="SuperAwesomePin"></div>
// }

const Marker = (props) => {
  const { color, name, id, title, promoteListing, location } = props;
  let button;
  if (promoteListing) {
    button = <div className="pulse" />;
  } else {
    button = "";
  }

  let [showInfoWindow, setShowInfoWindow] = useState(false);

  const handleMouseOver = (e) => {
    setShowInfoWindow(true);
  };
  const handleMouseExit = (e) => {
    setShowInfoWindow(false);
  };

  return (
    <div>
      <div
        className="pin bounce"
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${icon})`,
          width: "28",
          height: "32",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseExit}
        title={name}
      />
      {button}

      {showInfoWindow && (
        <div
          className="marker"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseExit}
        >
          <div className="markerTitle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="11"
              viewBox="0 0 8 11"
              fill="none"
            >
              <path
                d="M4.49375 10.7528C5.58125 9.36995 8 6.01831 8 4.1357C8 1.85159 6.20917 0 4 0C1.79167 0 0 1.85159 0 4.1357C0 6.01831 2.4375 9.36995 3.50625 10.7528C3.7625 11.0824 4.2375 11.0824 4.49375 10.7528ZM4 5.51427C3.26458 5.51427 2.66667 4.89607 2.66667 4.1357C2.66667 3.37534 3.26458 2.75714 4 2.75714C4.73542 2.75714 5.33333 3.37534 5.33333 4.1357C5.33333 4.89607 4.73542 5.51427 4 5.51427Z"
                fill="white"
              />
            </svg>
            <h4>{name}</h4>
          </div>
          <div className="markerText">
            <p>
              {location} - {title}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Marker;
