import React, { useState } from "react";
import { format, set } from "date-fns";
import location from "../whitepin.svg";
import placeholder from "../placeholder.png";
import virtual from "../virtual.svg";
import circleImg from "../circle-check-solid-white.svg";
import { Link } from "react-router-dom";

function ProviderCardOnline(props) {
  const linkhandleClick = (idClick) => {
    // e.preventDefault();
    const dataClick = async () => {
      const data = await (
        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/record-click?id=${idClick}`,
        )
      ).json();
      console.log("The link was clicked.");
    };
    dataClick();
  };

  return (
    <div key={props.provider.cpid + "wrapper"}>
      <div className="providerCardWrapper">
        <div id="provider-link" className="campAdCard resourcard">
          <div className="providercardimg" key={"video-" + props.provider.cpid}>
            {props.provider.ytLink != "" ? (
              <div className="video-responsive">
                <iframe
                  width="853"
                  height="480"
                  src={`https://www.youtube.com/embed/${props.provider.ytLink}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            ) : (
              <Link
                onClick={() => {
                  linkhandleClick(props.provider.cpid);
                }}
                to={
                  props.provider.websiteUrl +
                  "?tutm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"
                }
                target="_blank"
              >
                <div
                  className="providerImg"
                  key={"img-" + props.provider.cpid}
                  style={{
                    backgroundImage: `url(https://static.summercampscout.com/media/providers/${props.provider.image})`,
                  }}
                />
              </Link>
            )}
          </div>
          <div className="threepromo">
            <div
              key={"title-" + props.provider.cpid}
              className="providerHeader"
            >
              <Link
                onClick={() => {
                  linkhandleClick(props.provider.cpid);
                }}
                to={
                  props.provider.websiteUrl +
                  "?utm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"
                }
                target="_blank"
                className="cardAdTitle"
              >
                {props.provider.title}
              </Link>
              <p>
                Ages: {props.provider.minAge} to {props.provider.maxAge}
              </p>
            </div>
            <div className="virtualCardImg">
              <img className="cardVirtual" src={virtual} />
            </div>

            <div key={"desc-" + props.provider.cpid}>
              <p className="addescriptiontext">{props.provider.description}</p>
              <div className="promoTextSection">
                <Link
                  onClick={() => {
                    linkhandleClick(props.provider.cpid);
                  }}
                  id="pp-cta"
                  to={
                    props.provider.websiteUrl +
                    "?utm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"
                  }
                  target="_blank"
                  className="providerCardButton"
                >
                  CHECK AVAILABILITY
                </Link>
                <p className="promotedtextHome">Promoted</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProviderCardOnline;
