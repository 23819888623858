import { useNavigate, createSearchParams, useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef, ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";
import sliderIcon from '../slider-icon.png';
import Card from '../components/card';
import ProviderCard from '../components/ProviderHomeCard';
import Advertisement from '../components/Advertisement';
import PromoCard from '../components/promocard';
import ProviderMap from "../components/ProviderMap";
import closeBtn from '../close.svg';
import arrow from '../arrow.png';
import moment from 'moment'
import { format, set } from 'date-fns'
import Lottie from "react-lottie";
import * as animationData from "../location-pin.json";


const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function extractFromAdress(components, type){
  for (var i=0; i<components.length; i++)
      for (var j=0; j<components[i].types.length; j++)
          if (components[i].types[j]==type) return components[i].short_name;
  return "";
}





export default function ProviderHome(props) {
  
  
  let [mapWidth, setMapWidth] = useState(false);
  let [promoOrgs, setPromoOrgs] = useState(null);
  // let [filter, setFilter] = useState(false);
  let [camps, setCamps] = useState(null);
  let[campsFound, setCampsFound] = useState(0);
  let [lat,setlat] = useState(props.lat);
  let [lon, setLon] = useState(props.lon);
  let [specialNeeds, setSpecialNeeds] = useState(false);

  let [providers, setProviders] = useState(null);
  let[locations, setLocations] = useState(null);
  let [distance, setDistance] = useState(1000);
  const navigate = useNavigate();
  let location = useLocation();
  const { userName } = useParams();
  
  const mapLarge = event => {
    if(mapWidth == false){
      setMapWidth(true);
    }else{
      setMapWidth(false);
    }  
  };
  let currentData = moment().toISOString()
  

  
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    // fetch data
   
    const dataFetch = async () => {
      const data = await (
        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/all-providers`
        )
      ).json();

      // set state when the data received
      setProviders(data);
    };

    dataFetch();
  }, [props.location, props.lat, props.lon]);

  useEffect(() => {
    document.title = "Summer Camp Providers | Summer Camp Scout";
    
  
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Summer Camp Providers");
        document.querySelector('meta[property="og:description"]').setAttribute("content", "Discover Summer Camp Providers near you.");
        document.querySelector('meta[property="og:url"]').setAttribute("content",  "https//:summercampscout.com/providers");
        document.querySelector('meta[name="twitter:site"]').setAttribute("content",  "https//:summercampscout.com/providers");
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", "Summer Camp Providers");
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", "Discover Summer Camp Providers near you.");
        document.querySelector('meta[name="description"]').setAttribute("content", "Discover Summer Camp Providers near you.");
      
  }, []);

  

  useEffect(() => {
    // fetch data
    
    const dataFetchOne = async () => {
      const data = await (
        
        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/all-locations?distance=${distance ? distance : 100}&lat=${props.lat}&lon=${props.lon}`
        )
      ).json();
  
      // set state when the data received
      // setCampsFound(data.length)
      setLocations(data);
    };
    
    dataFetchOne();
  }, [props.location, distance, props.lat, props.lon, specialNeeds]);

  useEffect(() => {
    setTimeout(()=>{
    const allWithClass = Array.from(
      document.getElementsByClassName('providerCard')
    );
    console.log(allWithClass);
   
      setCampsFound(allWithClass.length)
     }, 1000)
    
  }, [distance, locations, specialNeeds]);



  const onChange = (event) => {
    
    if(event.target.value == "DEFAULT"){
      setDistance(parseInt(1000));
    }else{
      setDistance(parseInt(event.target.value));
    }
    
    
  };

  const handleSpecialNeeds = (event) => {
    if(event.target.checked == true){
      setSpecialNeeds(true);
    }else{
      setSpecialNeeds(false);
    }
  
  }

  useEffect(() => {
    // write data to csv
    const dataFetchOne = async () => {
      const data = await (
        
        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/all-promotions?distance=${distance ? distance : 100}&lat=${props.lat}&lon=${props.lon}`
        )
      ).json();
      setPromoOrgs(data)
      console.log(data);
    };
    if(props.lat, props.lon) {
      dataFetchOne();
    }
    
  }, [props.location, distance, props.lat, props.lon, specialNeeds]);

if(!locations || !promoOrgs) return <div className="loading"><Container>
<Lottie options={defaultOptions}
              height={100}
              width={100}
             />
             <h1>Retrieving location data...</h1>
              </Container></div>;
    return (
      <div>
      
      <Container fluid className={`${props.alert ? 'main-content' : 'main-content noalert'}`}>
        <Row>
          <Col lg="2" md="4" className="filterwrapper">

            <div className="mainfilter">
              <div className="filter-wrapper-top">
              <div>
                <h3 className="filterTitleDistance">Search Radius</h3>
                <select defaultValue={'DEFAULT'} onChange={onChange} id="distanceSelect" className="distance">
                <option value="10">10 miles</option>
                <option value="25">25 miles</option>
                <option value="50" >50 miles</option>
                <option  value="100">100 Miles</option>
                <option  value="DEFAULT">1000 miles</option>
              </select>
              </div>
              <div className="filterSection specialneeds">
                  <label className="checkLabel">
                    
                    <input
                      type="checkbox"
                      checked={props.specialNeeds}
                      onChange={handleSpecialNeeds}
                    />
                    Special Needs Friendly
                    </label>
              </div> 
              
              </div>
          
              <div>
          

          
    </div>
</div>           
            
         
          </Col>
          <Col lg={mapWidth == true ? 4 : 6} md="8">
            
              <div className="campFoundWrapper">
                
                </div>
              <div className="breadcrumbSection">
              <p>{props.citySearch}&nbsp; &nbsp;&nbsp;&nbsp; {props.searchTerm}</p>
              <h1 className="headline">Camp Providers in {props.citySearch}</h1>
              </div>
             
              <p className="campFound">Organizations Found: <span>{campsFound}</span></p>
             
              {promoOrgs.providerIds.map((location, index) => {
                
                return (
                    <Col className="providerCard" lg="12" key={"cardname-" + index}>
                    <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={index} zip={props.zipcode} specialNeeds={specialNeeds}/>
                  </Col>
                );
                
              })}
            
            
              
            
            
            </Col>
            <Col className="mapsection" lg={mapWidth == true ? 6 : 4} >
            <button className="expand" onClick={mapLarge}>
            Expand Map
            </button>

       
            
        <ProviderMap locations={locations} providers={providers} lat={props.lat} lon={props.lon} searchTerm={props.searchTerm} startDate={props.startDate} endDate={props.endDate} age={props.age} cost={props.cost}/> 
   
          </Col>
        </Row>
      </Container>
      
      </div>
    );
  }