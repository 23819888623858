import React, { useState, useEffect, useRef, ReactElement } from "react";
import GoogleMapReact from "google-map-react";
// import useGeolocation from "react-hook-geolocation";
import Marker from "../Marker";
import MarkerPromo from "../MarkerPromo";
import moment from "moment";

function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
      style: maps.ZoomControlStyle.SMALL,
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
  };
}

function SimpleMap(props) {
  // const geolocation = useGeolocation();
  let [currentlat, setCurrentLat] = useState(null);
  let [currentlon, setCurrentLon] = useState(null);
  let [search, setSearch] = useState("All");

  var defaultProps = {
    center: {
      lat: parseFloat(props.lat),
      lng: parseFloat(props.lon),
    },
    zoom: 11,
  };

  useEffect(() => {
    setCurrentLat(props.lat);
    setCurrentLon(props.lon);
    setSearch(props.searchTerm);
  }, [props.lat, props.lon, props.searchTerm, props.camps, props.promoOrgs]);

  return (
    // Important! Always set the container height explicitly
    <div className="mapDiv">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E" }}
        center={[props.lat, props.lon]}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}
      >
        {props.camps.map((camps) => {
          if (
            (camps.title.toUpperCase().includes(props.searchTerm) ||
              camps.organization.toUpperCase().includes(props.searchTerm) ||
              props.searchTerm == "All" ||
              props.searchTerm == "ALL") &&
            (moment(props.startDate).format("MM/DD/YYYY") <=
              moment(camps.startDate).format("MM/DD/YYYY") ||
              props.startDate == 0) &&
            (moment(props.endDate).format("MM/DD/YYYY") >=
              moment(camps.endDateTime).format("MM/DD/YYYY") ||
              props.endDate == 0) &&
            ((camps.ageMin <= props.age && camps.ageMax >= props.age) ||
              props.age == 0) &&
            (camps.cost <= props.cost || props.cost == 100)
          ) {
            const campLatLon = camps.geoJson.split(",");
            return (
              <Marker
                key={camps.rangeKey}
                promoteListing={camps.promoted ? camps.promoted : false}
                lat={parseFloat(campLatLon[0])}
                lng={parseFloat(campLatLon[1])}
                name={camps.locDescription}
                location={camps.organization}
                title={camps.title}
                color={"#0583F2"}
              />
            );
          }
        })}

        {props.promoOrgs.locationsAll.map((camp, index) => {
          const campLatLon = camp.geoJson.split(",");
          var title = "";

          props.promoOrgs.providers.map((provider) => {
            if (provider.cpid === camp.cpid) {
              title = provider.title;
            }
          });
          return (
            <MarkerPromo
              key={index}
              promoteListing={camp.promoted}
              lat={parseFloat(campLatLon[0])}
              lng={parseFloat(campLatLon[1])}
              name={title}
              location={camp.address}
              title={camp.description}
              color={"#1F57A0"}
            />
          );

          // });
        })}
      </GoogleMapReact>
    </div>
  );
}

export default SimpleMap;

// active
// :
// true
// address
// :
// "1135 NE Campus Pkwy"
// city
// :
// "Seattle"
// cpid
// :
// "c8228d96-cb96-11ee-bb2f-f3bbb4d434d3"
// description
// :
// "University of Washington"
// geoJson
// :
// "47.6558332,-122.3161575"
// geohash
// :
// 6093393332968659000
// hashKey
// :
// 609
// promoted
// :
// true
// rangeKey
// :
// "3637c7f1-27d9-4494-be79-b65c16a2e57c"
// state
// :
// "WA"
// userid
// :
// "32756f97-f254-4c40-a894-935dc5a1e42d"
// zip
// :
// "98105"
