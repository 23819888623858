import {
  Outlet,
  Link,
  useNavigate,
  createSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";

import React, { useState, useEffect, useRef, ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../logo.svg";
import sliderIcon from "../slider-icon.png";
import Hamburger from "hamburger-react";

const globalquery = `query {
    globalSettingsCollection{
      items{
        title
        notifications
        menuCollection{
          items{
                title
                slug
        		}

        }
        }
      }
    }`;

function extractFromAdress(components, type) {
  for (var i = 0; i < components.length; i++)
    for (var j = 0; j < components[i].types.length; j++)
      if (components[i].types[j] == type) return components[i].short_name;
  return "";
}

export default function Header(props) {
  const navigate = useNavigate();

  let [zipcodeSearch, setZipcodeSearch] = useState("");
  let [citySearch, setCitySearch] = useState("");

  let [stateSearch, setStateSearch] = useState("");
  //let [zip, setZip] = useState(null);
  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);
  let [distance, setDistance] = useState(null);
  let [cost, setCost] = useState(null);
  const [isOpen, setOpen] = useState(false);

  let [notification, setNotification] = useState("");
  let [filter, setFilter] = useState(false);
  const zipInput = useRef(null);
  const searchInput = useRef(null);
  const zipInputMobile = useRef(null);
  // const navigate = useNavigate();

  useEffect(() => {
    setOpen(false);
  }, []);
  useEffect(() => {
    const keyDownHandler = (event) => {
      console.log("User pressed: ", event.key);

      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (props.hasLoaded) {
      window
        .fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${props.lat},${props.lon}&key=AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E`,
          {
            method: "POST",
          },
        )
        .then((response) => response.json())
        .then((json) => {
          var zip = extractFromAdress(
            json.results[0].address_components,
            "postal_code",
          );
          var city = extractFromAdress(
            json.results[0].address_components,
            "locality",
          );
          var state = extractFromAdress(
            json.results[0].address_components,
            "administrative_area_level_1",
          );
          setZipcodeSearch(city + ", " + state + " " + zip);
          // setZip(zip);
          setCitySearch(city);
          setStateSearch(state);
          props.setZipcode(zip);
          props.citySearch(city);
          // props.newSearch(searchInput.current.value);
          //     const options = {
          //       pathname: `l=${zip}&s=${searchInput.current.value}`,
          //       search: "/?"

          //     };
          //     navigate(options, { replace: true });
        });
    }
  }, [props.lat, props.hasLoaded]);

  const handleSubmit = (event) => {
    console.log("form submitted ✅");

    zipInput.current.blur();
    if (zipInput.current.value.length === 5) {
      window
        .fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${zipInput.current.value}&sensor=true&key=AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E`,
          {
            method: "POST",
          },
        )
        .then((response) => response.json())
        .then((json) => {
          //  console.log(extractFromAdress(json.results[0].address_components, "long_name"));
          var zip = extractFromAdress(
            json.results[0].address_components,
            "postal_code",
          );
          var city = extractFromAdress(
            json.results[0].address_components,
            "locality",
          );
          var state = extractFromAdress(
            json.results[0].address_components,
            "administrative_area_level_1",
          );
          var newLat = json.results[0].geometry.location.lat;
          var newLon = json.results[0].geometry.location.lng;
          props.setZip(zip);
          console.log(
            extractFromAdress(
              json.results[0].address_components,
              "postal_code",
            ),
          );
          setZipcodeSearch(city + ", " + state + " " + zip);
          setCitySearch(city);
          setStateSearch(state);
          zipInput.current.value = city + ", " + state + " " + zip;

          props.citySearch(city);
          props.newLat(parseFloat(newLat));
          props.newLon(parseFloat(newLon));
          props.newSearch(searchInput.current.value);
          const options = {
            pathname: "/",
            search: `?l=${zip}&s=${searchInput.current.value}`,
          };
          navigate(options, { replace: true });
        });
    } else {
      var newLat = null;
      var newLon = null;
      window
        .fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${zipInput.current.value}&sensor=true&key=AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E`,
          {
            method: "POST",
          },
        )
        .then((response) => response.json())
        .then((json) => {
          //  console.log(extractFromAdress(json.results[0].address_components, "long_name"));
          var zip = extractFromAdress(
            json.results[0].address_components,
            "postal_code",
          );
          var city = extractFromAdress(
            json.results[0].address_components,
            "locality",
          );
          var state = extractFromAdress(
            json.results[0].address_components,
            "administrative_area_level_1",
          );
          newLat = json.results[0].geometry.location.lat;
          newLon = json.results[0].geometry.location.lng;
          props.setZip(zip);
          console.log(extractFromAdress(json.results[0]));
          setCitySearch(city);
          setStateSearch(state);
          zipInput.current.value = city + ", " + state + " " + zip;

          props.citySearch(city);
          props.newLat(parseFloat(newLat));
          props.newLon(parseFloat(newLon));
          props.newSearch(searchInput.current.value);
        })
        .then((json) => {
          window
            .fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${newLat},${newLon}&key=AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E`,
              {
                method: "POST",
              },
            )
            .then((response) => response.json())
            .then((json) => {
              var zip = extractFromAdress(
                json.results[0].address_components,
                "postal_code",
              );
              var city = extractFromAdress(
                json.results[0].address_components,
                "locality",
              );
              var state = extractFromAdress(
                json.results[0].address_components,
                "administrative_area_level_1",
              );
              setZipcodeSearch(city + ", " + state + " " + zip);
              props.setZipcode(zip);
              zipInput.current.value = city + ", " + state + " " + zip;
              props.newSearch(searchInput.current.value);
              const options = {
                pathname: "/",
                search: `?l=${zip}&s=${searchInput.current.value}`,
              };
              navigate(options, { replace: true });
            });
        });
    }
  };

  function setFilterSettings(filterSetting) {
    props.setFilter(filterSetting);
    props.setFilterDescription(true);
  }

  function setMenu() {
    setOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }

  function Alert(props) {
    return (
      <div className="notification container-full">
        <div className="row">
          <div className="col-md-12">
            <p>{props.alert}</p>
          </div>
        </div>
      </div>
    );
  }

  return props.lat != null ? (
    <div className="topSection">
      {props.alert ? <Alert alert={props.alert} /> : ""}

      <header className={isOpen ? "page-header header-open" : "page-header"}>
        <div className="header">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>

          {!isOpen && (
            <div className="searchbox">
              <form className="search">
                <div className="Icon-inside" id="camp">
                  <input
                    className="campsearch input-field"
                    type="text"
                    name="camp"
                    placeholder="Search for a camp or camp provider ..."
                    defaultValue={props.searchTerm}
                    // onChange={event => setNewSearch(event.target.value)}
                    // onFocus={(e) => e.target.value = ""}
                    ref={searchInput}
                  />
                </div>
                <div className="filterBtnWrapper">
                  <button
                    type="button"
                    className="filterBtn"
                    onClick={() => setFilterSettings(!props.filter)}
                  >
                    <img className="filterIcon" src={sliderIcon} />
                  </button>
                  <div className="Icon-inside" id="location">
                    <input
                      className="input-field"
                      type="text"
                      name="location"
                      placeholder="Location..."
                      defaultValue={zipcodeSearch}
                      onChange={(event) => props.setZip(event.target.value)}
                      onFocus={(e) => (e.target.value = "")}
                      ref={zipInput}
                    />
                    <i
                      className="fa fa-user fa-lg fa-fw"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                {!props.filterDescription ? (
                  <p className="filterDirections">
                    Use filters and search to narrow results.
                  </p>
                ) : (
                  <></>
                )}
                <i className="fa fa-user icon"></i>
              </form>
            </div>
          )}

          <div className="subbutton">
            <div
              className={isOpen ? "mobileMenu mobileMenuShow" : "mobileMenu"}
            >
              {isOpen && (
                <div className="searchbox navBox">
                  <div
                    className={
                      isOpen ? "navWrapper navWrapperOpen" : "navWrapper"
                    }
                  >
                    {props.globalData.menuCollection.items.map((menu) => {
                      return (
                        <a className="navItem" href={"/" + menu.slug}>
                          {menu.title}
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="buttonsection">
                <Link
                  to="https://provider.summercampscout.com/"
                  className="cta-submit"
                >
                  Submit Camps
                </Link>
                <div className="btndescription">
                  <p>It's Free!</p>
                </div>
              </div>
            </div>
            <Hamburger toggled={isOpen} toggle={setMenu} />
          </div>
        </div>
      </header>
    </div>
  ) : (
    ""
  );
}
